@import "../../variables.scss";
.logo {
  border-radius: 50% 50% 50% 50%;
  width: 50px;
  height: 50px;
  float: left;
}

.white {
  color: $white;
}
