@import "../../variables.scss";

body>#root>div {
  height: 100vh;
}

.backgroundImageRichard {
  min-height: calc(100vh - 76px);

  background-image: url("../../richardBackground.jpg");
  background-position: right 50% top 30px;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    background-position: right 50% top 200px;
  }
}

.carousel-item>img {
  color: $black;
  height: 500px;
  display: block;
  margin: 0 auto;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: $white;
  font-size: 7em;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.col-nospacing {
  flex-grow: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-height {
  min-height: calc(100vh - 132px);
}